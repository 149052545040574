.skin-blue aside.main-sidebar {
    background: #222d32;
}

.skin-blue aside.main-sidebar .sidebar {
    background: #222d32;
}

/* Sidebar Version Text */

.sidebar-version-main{
    display: flex;

}
.sidebar-version {
    width: 100%;
    font-size: 12px;
    background: #F08E4F;
    padding: 6px 18px;
    color: #fff;
    font-weight: 500;
  }