.modal-backdrop {
  opacity: 0 !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  text-align: center;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog.mini {
  min-width: 500px !important;
}

.modal-dialog {
  min-width: 950px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  background: "#fff";
}

.modal-dialog .btn {
  margin-left: 10px;
}

.modal-dialog-content {
  position: relative;
}

.modal-dialog-content .option {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9999;
}

.modal-dialog-content .custom-modal-content {
  width: 100%;
  float: left;
  height: 90%;
  background: "#fff";
  padding: 20px;
  overflow: auto;
}
